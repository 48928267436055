import {
  cilAperture,
  cilApplications,
  cilClipboard,
  cilClone,
  cilWalk,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CNavGroup, CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
    permission: ["all"],
  },
  {
    component: CNavGroup,
    name: "Properties",
    icon: <CIcon icon={cilAperture} customClassName="nav-icon" />,
    permission: ["all"],
    items: [
      {
        component: CNavItem,
        name: "Menu Member",
        to: "/properties/member-menu",
        permission: "all",
      },
      {
        component: CNavItem,
        name: "Properties Perusahaan",
        to: "/properties/company",
        permission: "all",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Data",
    icon: <CIcon icon={cilClone} customClassName="nav-icon" />,
    permission: ["MAIN_DATA"],
    items: [
      {
        component: CNavItem,
        name: "Admin",
        to: "/data/admin",
        permission: "MAIN_DATA",
      },
      {
        component: CNavItem,
        name: "Karyawan",
        to: "/data/karyawan",
        permission: "MAIN_DATA",
      },
      {
        component: CNavItem,
        name: "Lokasi Kerja",
        to: "/data/lokasi-kerja",
        permission: "MAIN_DATA",
      },
      {
        component: CNavItem,
        name: "Shift",
        to: "/data/shift",
        permission: "MAIN_DATA",
      },
      {
        component: CNavItem,
        name: "Notifikasi",
        to: "/data/notifikasi",
        permission: "MAIN_DATA",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Absensi",
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
    permission: ["ATTENDANCE"],
    items: [
      {
        component: CNavGroup,
        name: "Data Absen",
        // to: "/absensi-qr",
        permission: ["all"],
        items: [
          {
            component: CNavItem,
            name: "Absen QR",
            to: "/absensi-qr",
            permission: "QR",
          },
          {
            component: CNavItem,
            name: "Absen Foto",
            to: "/absensi-foto",
            permission: "SELFIE",
          },
        ],
      },
      {
        component: CNavItem,
        name: "Data Patroli",
        to: "/absensi/patrol",
        permission: "PATROL",
      },
      {
        component: CNavItem,
        name: "Data Lembur",
        to: "/absensi/lembur",
        permission: "ATTENDANCE",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Binlat",
    to: "/binlat",
    icon: <CIcon icon={cilWalk} customClassName="nav-icon" />,
    permission: "EVENT",
  },
];

export default _nav;
