import {
  CAvatar,
  CButton,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import React from "react";

import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ImageIcon from "src/assets/images/avatars/5.jpg";
import { useAppSelector } from "src/core/feature/hooks";
import { removeToken } from "src/core/feature/token/tokenSlice";
import { removeUser } from "src/core/feature/user/userSlice";
import { removeMenu } from "src/core/feature/menu/menuSlice";

const AppHeaderDropdown = () => {
  const user = useAppSelector((state) => state.user.user);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logout = () => {
    // dispatch(setLoading(true));
    // dispatch(setLoading(false));
    dispatch(removeToken());
    dispatch(removeUser());
    dispatch(removeMenu());
    toast.success("Berhasil");
    navigate("/");
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <CDropdown variant="nav-item">
      <Modal isOpen={modalIsOpen} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi</ModalHeader>
        <ModalBody>Apakah Anda yakin ingin keluar?</ModalBody>
        <ModalFooter>
          <CButton
            color=""
            type="button"
            size="sm"
            style={{
              background: "#fff",
              color: "#E50200",
              border: "1px solid #E50200",
            }}
            onClick={() => closeModal()}
          >
            Batal
          </CButton>
          <CButton
            color=""
            type="submit"
            size="sm"
            style={{
              background: "#0054E6",
              color: "#fff",
            }}
            onClick={() => logout()}
          >
            Keluar
          </CButton>
        </ModalFooter>
      </Modal>

      <CDropdownToggle
        placement="bottom-end"
        className="py-0 fw-semibold"
        caret={false}
      >
        <CAvatar
          // src={ImageIcon}
          size="md"
          color="danger"
          status="success"
          style={{ color: "white" }}
        >
          {user?.name
            ? user?.name
                .split(" ")
                .map((el, ii) => {
                  if (ii == 0 || ii == user?.name.split(" ").length - 1) {
                    return el.substring(0, 1);
                  }
                })
                .join("")
                .toUpperCase()
            : ""}
        </CAvatar>
        {/* {user?.name} */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-2" placement="bottom-end">
        <CDropdownHeader
          className="bg-light fw-semibold py-2 mb-2"
          style={{ fontSize: "16px" }}
        >
          {user?.name}
        </CDropdownHeader>
        {/* <CDropdownItem href="#">
          <FontAwesomeIcon icon={faLock} className="me-2" />
          Ganti Password
        </CDropdownItem> */}
        {/* <CDropdownDivider /> */}
        <CDropdownItem
          onClick={() => {
            openModal();
          }}
        >
          {/* <CIcon icon={cilAccountLogout} className="me-2" /> */}
          <FontAwesomeIcon icon={faPowerOff} className="me-2" />
          Keluar
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
