import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContent, AppHeader, AppSidebar } from "./components";
import { useAppSelector } from "./core/feature/hooks";
import Routing from "./routes";
import "./scss/style.scss";

const loading = (
  <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
        <AppContent />
      </div>
      {/* <AppFooter /> */}
    </div>
  </div>
  // <div className="pt-3 text-center">
  //   <div className="sk-spinner sk-spinner-pulse"></div>
  // </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

function App() {
  let isLoading = useAppSelector((state) => state.config.loading);
  return (
    <BrowserRouter>
      {/* <ToastContainer theme="light"></ToastContainer> */}
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: 5000 }}
      ></Toaster>
      <LoadingOverlay active={isLoading} spinner text="Harap Menunggu ...">
        <Suspense fallback={loading}>
          <Routes>
            {Routing.map((prop, index) => {
              return (
                <Route
                  key={"menu-" + index}
                  path={prop.path}
                  element={<prop.element />}
                />
              );
              // if (prop.path == '') {
              //   return (
              //     <Route
              //       key={`menu-${index}`}
              //       path=""
              //       element={<Navigate to={prop.redirect} replace />}
              //     />
              //   )
              // } else {
              // }
            })}
          </Routes>
        </Suspense>
      </LoadingOverlay>
    </BrowserRouter>
  );
}

export default App;
