import React from "react";
const Login = React.lazy(() => import("./views/pages/login/Login"));
const forgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/forgotPassword")
);
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Monitor = React.lazy(() =>
  import("./views/pages/data/superadmin/dashboard/monitor")
);
const Admin = React.lazy(() => import("./views/pages/data/admin/Admin"));
const LokasiKerja = React.lazy(() =>
  import("./views/pages/data/lokasi/Lokasi")
);
const Shift = React.lazy(() => import("./views/pages/data/shift/Shift"));
const Karyawan = React.lazy(() =>
  import("./views/pages/data/karyawan/Karyawan")
);
const newKaryawan = React.lazy(() => import("./views/pages/data/karyawan/Add"));
const editKaryawan = React.lazy(() =>
  import("./views/pages/data/karyawan/Edit")
);
const Notifikasi = React.lazy(() =>
  import("./views/pages/data/notifikasi/Notifikasi")
);
const Absensi = React.lazy(() => import("./views/pages/data/absensi/qr/absen"));
const Event = React.lazy(() => import("./views/pages/data/event/event"));
const detailEvent = React.lazy(() => import("./views/pages/data/event/detail"));
const LemburBackup = React.lazy(() =>
  import("./views/pages/data/absensi/qr/lembur")
);
const Patroli = React.lazy(() => import("./views/pages/data/patroli/patroli"));
const Company = React.lazy(() =>
  import("./views/pages/data/superadmin/company/company")
);
const NewCompany = React.lazy(() =>
  import("./views/pages/data/superadmin/company/new")
);
const EditCompany = React.lazy(() =>
  import("./views/pages/data/superadmin/company/edit")
);
const absenFoto = React.lazy(() =>
  import("./views/pages/data/absensi/foto/dataAbsen")
);
const masterNotification = React.lazy(() =>
  import("./views/pages/data/superadmin/notification/notification")
);
const propertiesMemberMenu = React.lazy(() =>
  import("./views/pages/data/properties/menuMember/menuMember")
);
const companyProperties = React.lazy(() =>
  import("./views/pages/data/properties/companyProperties/companyProperties")
);
const dataLembur = React.lazy(() => import("./views/pages/data/lembur/lembur"));

const routes = [
  { path: "", name: "Login", element: Login },
  { path: "/", name: "Login", element: Login },
  { path: "/forgot-password", name: "Lupa Password", element: forgotPassword },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  {
    path: "/monitor",
    element: Monitor,
  },

  { path: "/data/lokasi-kerja", element: LokasiKerja },
  { path: "/data/shift", element: Shift },
  { path: "/data/admin", element: Admin },
  { path: "/data/karyawan", element: Karyawan },
  { path: "/data/karyawan/baru", element: newKaryawan },
  { path: "/data/karyawan/edit/:id", element: editKaryawan },
  { path: "/data/notifikasi", element: Notifikasi },
  { path: "/absensi-qr", element: Absensi },
  { path: "/binlat", element: Event },
  { path: "/binlat/detail/:id", element: detailEvent },
  { path: "/absensi-qr/lembur/:id", element: LemburBackup },
  { path: "/absensi/patrol", element: Patroli },
  { path: "/master-perusahaan", element: Company },
  { path: "/master-perusahaan/new", element: NewCompany },
  { path: "/master-perusahaan/edit/:id", element: EditCompany },
  { path: "/absensi-foto", element: absenFoto },
  { path: "/master-notification", element: masterNotification },
  { path: "/properties/member-menu", element: propertiesMemberMenu },
  { path: "/properties/company", element: companyProperties },
  { path: "/absensi/lembur", element: dataLembur },
];

export default routes;
