import { cilMenu } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { setLoading } from "src/core/feature/config/configSlice";
import { useAppSelector } from "src/core/feature/hooks";
import { removeMenu } from "src/core/feature/menu/menuSlice";
import { setSidebarShow } from "src/core/feature/template/templateSlice";
import { AppHeaderDropdown } from "./header/index";

const AppHeader = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user.user);
  const sidebarShow = useAppSelector((state) => state.template.sidebarShow);
  let navigate = useNavigate();

  const changeCompany = () => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
      dispatch(removeMenu());
      localStorage.removeItem("trigger");
      localStorage.setItem("triggerMenu", JSON.stringify("tampil"));
      toast.success("Berhasil");
      window.location.replace("/monitor");
    }, 1000);
  };

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(setSidebarShow(!sidebarShow))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        {/* <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand> */}
        {/* <CHeaderNav className="d-none d-md-flex me-auto">
          {user?.companyId ? (
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
              {user?.companyName}
            </div>
          ) : (
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
              SUPERADMIN
            </div>
          )} */}

        {/* <CNavItem></CNavItem> */}
        {/* <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        {/* </CHeaderNav> */}
        {user?._id &&
        JSON.parse(localStorage.getItem("trigger")) == "superlagi" ? (
          <>
            <CHeaderNav className="d-none d-md-flex me-auto"></CHeaderNav>
            <CHeaderNav>
              <CButton
                color=""
                type="button"
                size="sm"
                style={{
                  background: "#032E9A",
                  color: "#fff",
                }}
                onClick={() => {
                  changeCompany();
                }}
              >
                {" "}
                Kembali ke Dashboard Superadmin
              </CButton>
            </CHeaderNav>
          </>
        ) : (
          ""
        )}

        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  );
};

export default AppHeader;
