import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { CBadge } from "@coreui/react";
import { useAppSelector } from "src/core/feature/hooks";

export const AppSidebarNav = ({ items }) => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);
  const menu = useAppSelector((state) => state.menu.menu);
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  // setFilterNav(navigation.filter(el => data.includes(el.permission)))

  const navItem = (item, index) => {
    let arr = menu;
    // let vals =
    //   menu?.map((el) => {
    //     arr.push(el.key);
    //   }) || [];
    // console.log("aaaa", arr);
    // let data = user?.permissions;
    const { component, name, badge, icon, ...rest } = item;
    const Component = component;
    // console.log(val, item.permission, val.includes(item.permission));
    if (arr?.includes(item.permission) || item.permission == "all")
      return (
        <Component
          {...(rest.to &&
            !rest.items && {
              component: NavLink,
            })}
          key={index}
          {...rest}
        >
          {navLink(name, icon, badge)}
        </Component>
      );
  };
  const navGroup = (item, index) => {
    let arr = menu;
    // let vals =
    //   menu?.map((el) => {
    //     arr.push(el.key);
    //   }) || [];
    // let data = user?.permissions;
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    // console.log(item.permission);
    let val = item.permission?.map((e) => arr?.includes(e) || e == "all");
    if (val && val.reduce((a, b) => a || b))
      return (
        <Component
          idx={String(index)}
          key={index}
          toggler={navLink(name, icon)}
          visible={location.pathname.startsWith(to)}
          {...rest}
        >
          {item.items?.map((item, index) =>
            item.items ? navGroup(item, index) : navItem(item, index)
          )}
        </Component>
      );
  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </React.Fragment>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
