import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./config/configSlice";
import menuReducer from "./menu/menuSlice";
import templateReducer from "./template/templateSlice";
import tokenReducer from "./token/tokenSlice";
import userReducer from "./user/userSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    config: configReducer,
    token: tokenReducer,
    template: templateReducer,
    menu: menuReducer,
  },
});

export default store;
