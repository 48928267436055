import { cilBell, cilBuilding, cilMonitor } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Monitor Admin Dashboard",
    to: "/monitor",
    icon: <CIcon icon={cilMonitor} customClassName="nav-icon" />,
    permission: "all",
  },
  // {
  //   component: CNavItem,
  //   name: "Dashboard Super Admin",
  //   to: "/master-dashboard",
  //   icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
  //   permission: "all",
  // },
  {
    component: CNavItem,
    name: "Master Perusahaan",
    to: "/master-perusahaan",
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    permission: ["all"],
  },
  {
    component: CNavItem,
    name: "Master Notifikasi",
    to: "/master-notification",
    icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
    permission: ["all"],
  },
];

export default _nav;
