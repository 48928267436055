import React from "react";
import { useDispatch } from "react-redux";

import { CImage, CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";

import { AppSidebarNav } from "./AppSidebarNav";

import icon from "src/assets/images/logo/LOGO_LUJ.png";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import { useAppSelector } from "src/core/feature/hooks";
import { setSidebarShow } from "src/core/feature/template/templateSlice";
import navigation from "../_nav";
import navigationSuper from "../_navSuper";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useAppSelector(
    (state) => state.template.sidebarUnfoldable
  );
  const sidebarShow = useAppSelector((state) => state.template.sidebarShow);
  const userData = useAppSelector((state) => state.user.user);
  let count = 0;
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => dispatch(setSidebarShow(visible))}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage
          src={icon}
          style={{
            objectFit: "contain",
            width: 70,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {userData?.type == "SUPERADMIN" ||
          JSON.parse(localStorage.getItem("triggerMenu")) == "tampil" ? (
            <AppSidebarNav items={navigationSuper} />
          ) : (
            <AppSidebarNav items={navigation} />
          )}
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(setSidebarUnfoldable(!unfoldable))}
      /> */}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
